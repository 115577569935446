.my-projects-container {
  position: relative;
  width: 100%;
  padding: 3rem 0;
}

@media screen and (min-width: 768px) {
  .my-projects-container {
    padding: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .my-projects-container {
    height: 500px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1536px) {
  .my-projects-container {
    height: 600px;
  }
}

.dim-layer {
  position: absolute;
  height: 110%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  z-index: 10;
  border-radius: 15px;
  transition-property: background-color opacity;
  transition: 0.2s ease-out;
}

.dim-layer.active {
  background-color: #535b63;
}

.dim-layer.inactive {
  background-color: inherit;
  opacity: 0;
}

.card-project {
  width: 100%;
  height: 12rem;
  cursor: pointer;
  overflow: hidden;
  background-color: rgba(60, 60, 74, 0.9);
  border: 2px solid #394047;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .card-project {
    height: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .card-project .project-content {
    background-color: rgba(40, 45, 51, 0.9);
  }

  .card-project.opened-card .project-content {
    background-color: rgba(40, 45, 51, 1);
  }
}

.card-project .project-content .project-description-wrapper {
  background-color: rgba(211, 211, 211, 0.3);
  font-family: "Karla", sans-serif;
}

.card-project .project-content .project-description-wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(211, 211, 211, 0.3);
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  overflow: hidden;
}

.card-project
  .project-content
  .project-description-wrapper::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: rgb(255, 255, 255);
}

@media screen and (min-width: 430px) and (max-width: 639px) {
  .card-project .project-content .project-title {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 470px) and (max-width: 767px) {
  .card-project .project-content .project-description,
  .card-project .project-content .project-date {
    font-size: 0.9rem;
  }
}

.card-project .project-content h6 {
  font-weight: 700;
  color: rgba(255, 255, 255, 0.76);
}

.project-preview {
  border-radius: 10px;
}

.card-project:nth-child(1) {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.card-project:nth-child(1) .project-preview {
  background-image: url("../../assets/images/projects_previews/omw_preview.png");
  background-position: top center;
  background-size: cover;
}

@media screen and (max-width: 639px) {
  .card-project.opened-card:nth-child(1) .project-preview {
    background-image: url("../../assets/images/projects_previews/omw_mob_preview.png");
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.card-project:nth-child(2) {
  grid-column: 3;
  grid-row: 1;
}

.card-project:nth-child(2) .project-preview {
  background-image: url("../../assets/images/projects_previews/wcv_preview.png");
  background-position: top left;
  background-size: cover;
}

@media screen and (max-width: 639px) {
  .card-project.opened-card:nth-child(2) .project-preview {
    background-image: url("../../assets/images/projects_previews/wcv_mob_preview.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.card-project:nth-child(3) {
  grid-column: 1;
  grid-row: 2;
}

.card-project:nth-child(3) .project-preview {
  background-image: url("../../assets/images/projects_previews/cap_preview.png");
  background-position: top left;
  background-size: cover;
}

@media screen and (max-width: 639px) {
  .card-project.opened-card:nth-child(3) .project-preview {
    background-image: url("../../assets/images/projects_previews/cap_mob_preview.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.card-project:nth-child(4) {
  grid-column: 2 / span 2;
  grid-row: 2;
}

.card-project:nth-child(4) .project-preview {
  background-image: url("../../assets/images/projects_previews/mwa_preview.png");
  background-position: top left;
  background-size: cover;
}

@media screen and (max-width: 639px) {
  .card-project.opened-card:nth-child(4) .project-preview {
    background-image: url("../../assets/images/projects_previews/mwa_mob_preview.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.btn-off {
  pointer-events: none;
}

.btn-on {
  background-color: rgba(255, 255, 255, 0.856);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  pointer-events: all;
}

.opened-card {
  border-radius: 15px;
  cursor: pointer;
  height: 80%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 30;
  grid-column: auto !important;
  grid-row: auto !important;
  pointer-events: auto;
}

@media screen and (min-width: 536px) {
  .opened-card {
    height: 85%;
  }
}

@media screen and (min-width: 640px) {
  .opened-card {
    width: 85%;
  }
}

@media screen and (min-width: 768px) {
  .opened-card {
    width: 75%;
    height: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .opened-card {
    height: 95%;
  }
}

@media screen and (min-width: 1280px) {
  .opened-card {
    width: 80%;
  }
}

@media screen and (min-width: 1536px) {
  .opened-card {
    height: 90%;
  }
}

.controlled-z-index {
  z-index: 998;
}

.not-controlled-z-index {
  transition-property: z-index;
  transition-delay: 0.3s;
  z-index: 10;
}
