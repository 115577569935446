@import "./styles/home_page/AboutMe.css";
@import "./styles/home_page/MyProjects.css";
@import "./styles/home_page/ContactMe.css";

/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
  background-color: rgb(64, 73, 82);
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.65);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.25);
}
